import React, { useState, useEffect } from "react";
import {
  GetDataWithToken,
  PostImageDataWithToken,
} from "../../SiteKey/Api/ApiHelper";
import toastr from "toastr";
import 'toastr/build/toastr.min.css';
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Variables from "../../variables";
const {API} = Variables

const AccountInformationHome = ({props}) => {
  const navigate = useNavigate()
  const [userinfo, setUserInfo] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [youtubeMyChannel, setyoutubeMyChannel] = useState("");
  const referralCode = localStorage.getItem("referral")

  //Save Capmaign
  const updateAccountInfo = async (event) => {
    /*console.log(localStorage.getItem('_token'));
    return false;*/
    setIsLoading(true);
    event.preventDefault();
    const formData = new FormData(event.currentTarget);

    // check if  youtube channel exist or not
        const youtubeChannelID = youtubeMyChannel.match(/[^\/]+$/)[0]
        if(!youtubeChannelID.startsWith("@")) localStorage.setItem("youtubeChannelId",youtubeChannelID)
        if(youtubeChannelID.startsWith("@")){
          try{
            const res = await axios.get(`https://www.googleapis.com/youtube/v3/search?part=snippet&type=channel&q=${youtubeChannelID}&key=${API}`)
            const snippet = res?.data?.items[0]?.snippet?.channelId  
            // console.log("youtube url is =====>>>>>",res?.data)

              if(!snippet){  setIsLoading(false); return toastr.error("Please Enter Valid Youtube Channel") }
              localStorage.setItem("youtubeChannelId",snippet)
            }
            catch(err){
              console.error("Google error =>",err.response.data.error.message)
            }
        }
    //
    const Endpoints = "update-user-info";
    const data = formData;
    //console.log("Form Data = "+JSON.stringify(data));return false;
    PostImageDataWithToken(Endpoints, data).then((response) => {
      response = JSON.parse(JSON.stringify(response));
      
      if (response.success) {
        if(response.message[0] === "Youtube channel already used."){
          setIsLoading(false);
          return toastr.error("Youtube channel already used.")
        } 
        toastr.success("Successfully updated!", "", {
          closeButton: true,
          progressBar: true,
          timeOut: 3000,
          positionClass: "toast-top-right",
          showMethod: "fadeIn",
          hideMethod: "fadeOut",
        })
        setTimeout(function(){
          window.location.reload();
          setIsLoading(false);
        }, 2000);

      } else {
        setIsLoading(false);
        console.log("error =" + response.data);
        toastr.error(response.data, "", {
          closeButton: true,
          progressBar: true,
          timeOut: 3000,
          positionClass: "toast-top-right",
          showMethod: "fadeIn",
          hideMethod: "fadeOut",
        })
      }
    });
  };

  const GetRecordDetails = () => {
    let Endpoints = "user-info";
    // console.log("endpoints:"+Endpoints);
    GetDataWithToken(Endpoints, "", "")
      .then((response) => {
        //console.log('response type='+JSON.stringify(response));return false;
        response = JSON.parse(JSON.stringify(response));
        //onsole.log(response.data?.campaign);return false;
        if (response?.success) {
          localStorage.setItem("channelName",response.data?.user_info?.youtube_channel)
          // console.log("response is ====>>>>",response)

          setUserInfo(response.data?.user_info);
        }
      })
      .catch((err) => {
        toastr.error("Login no longer exist. Login Again")
        localStorage.clear()
        navigate("/")
        setUserInfo([]);
      });
  };

  useEffect(() => {
    GetRecordDetails();    
  }, []);

 

  return (
    <main className="main-container">
      <div className="main-title">
        <h3>Account Information</h3>
      </div>

      <div className="card">
        <form
          method="POST"
          onSubmit={updateAccountInfo}
          className="mt-4"
          encType="multipart/form-data"
        >
          <div>
            <div style={{ marginTop: 20, color: "#fff" }}>Full Name</div>
            <input
              type="text"
              className="form-select"
              placeholder="Change my name"
              aria-label="Amount (to the nearest dollar)"
              style={{color:"black",fontWeight:"bold"}}
              disabled
              defaultValue={userinfo.name}
            />
            <input type="hidden" value={userinfo.name} name="name"/>
          </div>
          <div>
            <div style={{ marginTop: 20, color: "#fff" }} >Youtube Channel * <span style={{color:"red"}}> (If Youtube URL not work then use youtube channel id url example :- https://youtube.com/channel/UHNPMNHGHJJHUHUNU4g) <br/> to Get Channel ID <a href="https://www.youtube.com/account_advanced" target="_blank">click here</a></span></div>
            {
              userinfo.youtube_channel !== null ? (
                <>
                <input
                  type="url"
                  required={true}
                  name="youtube_channel"
                  style={{color:"black",fontWeight:"bold"}}
                  className="form-select"
                  placeholder="Enter YouTube Channel URL"
                  aria-label="Amount (to the nearest dollar)"
                  disabled
                  defaultValue={userinfo.youtube_channel}
              />
               <input type="hidden" className="form-select" placeholder="Enter YouTube Channel URL" value={userinfo.youtube_channel} name="youtube_channel"/>
               </>
              ):(
               <input type="url" className="form-select" onChange={(e)=>setyoutubeMyChannel(e.currentTarget.value)} placeholder="Enter YouTube Channel URL" value={userinfo.youtube_channel} name="youtube_channel"/>
              )
            }
           
          </div>
          <div>
            <div style={{ marginTop: 20, color: "#fff" }}>Email</div>
            <input
              type="email"
              name="email"
              className="form-select"
              placeholder="Email"
              disabled
              style={{color:"black",fontWeight:"bold"}}
              aria-label="Amount (to the nearest dollar)"
              defaultValue={userinfo.email}
            />
             <input type="hidden" value={userinfo.email} name="email"/>
          </div>
          <div>
            {/* <div style={{ marginTop: 20, color: "#fff" }}>Username</div>
            <input
              type="text"
              name="user_name"
              required={true}
              className="form-select"
              placeholder="Username"
              aria-label="Amount (to the nearest dollar)"
              defaultValue={userinfo.email}
              style={{color:userinfo.email?"black":"black",fontWeight:userinfo.user_name?"bold":""}}
              disabled
            /> */}
             <input type="hidden" value={userinfo.email} name="user_name"/>
          </div>
          <div>
            {/* <div style={{ marginTop: 20, color: "#fff" }}>About</div>
            <input
              type="text"
              name="about"
              required={true}
              className="form-select"
              placeholder="Write about yourself"
              aria-label="Amount (to the nearest dollar)"
              style={{color:userinfo.about?"black":"white",fontWeight:userinfo.about?"bold":""}}
              disabled={userinfo.about && true}
              defaultValue={userinfo.about}
            /> */}
             <input type="hidden" value={"empty"} name="about"/>
          </div>
          <div>
            <div style={{ marginTop: 20, color: "#fff" }}>Used Referral Code</div>
            <input
              type="text"
              name="affiliated_used_code"
              className="form-select"
              placeholder="Used Referral Code"
              aria-label="Amount (to the nearest dollar)"
              defaultValue={referralCode || userinfo.affiliated_used_code}
              style={{color:userinfo.affiliated_used_code?"black":"white",fontWeight:userinfo.affiliated_used_code?"bold":""}}
              disabled={userinfo.affiliated_used_code && true}
            />
          </div>
          {isLoading ? <button type="button" className="btn btn-primary" style={{ marginTop: 20,display:userinfo.youtube_channel && "none" }}>
                Wait...
            </button> : <button
            type="submit"
            className="btn btn-primary"
            style={{ marginTop: 20, alignSelf: "flex-start",display:userinfo.youtube_channel && "none" }}
          >
              Save
            </button>}
          {/* <button
            type="button"
            className="btn btn-primary"
            style={{ marginTop: 20, alignSelf: "flex-start" }}
            onClick={() => {
              props.navigate("/createCapmaignNext")
            }}
          >
            Save
          </button> */}
        </form>
      </div>
    </main>
  );
};



export default AccountInformationHome;

import React from 'react'
import { Outlet, useNavigate, useLocation } from 'react-router-dom'
import toastr from 'toastr'

//
export const ProtectedRoutes = () => {
  const Navigate = useNavigate()
  const {pathname} = useLocation()
  const auth = localStorage.getItem('loggedin');
  // check Channel Name
  if(pathname !== "/account-information"){
  if(localStorage.getItem("channelName") !== undefined){
    if(localStorage.getItem("channelName") === "null"){
      Navigate("account-information",{required:true})
      toastr.info("Please Enter Youtube Channel For Explore Site")
    }
}
}
 
  
  return auth ? <Outlet/> : <Navigate to={"/"} />;
}

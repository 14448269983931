import { googleLogout } from "@react-oauth/google";

const fnToLogout = () => {
  localStorage.clear();
  localStorage.setItem("loggedin", false);
  localStorage.removeItem("loggedin");
  localStorage.removeItem("_token");
  localStorage.removeItem("user_name");
  localStorage.removeItem("user_id");
  localStorage.removeItem("user");
  //
  googleLogout();
  //
  window.location.replace("/")
};

export default fnToLogout;
